import { onBeforeMount } from "vue";
import { useHeaderToolbar } from "@/composables/useHeaderToolbar";

/*
'has-toolbar pt-34 lg:pt-40': toolbar,
      'pt-20': !toolbar,
*/


export default {
  __name: 'index',
  props: {
  skin: {
    type: String,
    required: false,
    validator: function (value) {
      return ["white", "ice"].indexOf(value) !== -1;
    },
    default: "white",
  },
  scrollDirection: {
    type: String,
    required: false,
    validator: function (value) {
      return ["vertical", "horizontal"].indexOf(value) !== -1;
    },
    default: "vertical",
  },
  alignToHeader: {
    type: Boolean,
    required: false,
    default: false,
  },
  spaceFromHeader: {
    type: Boolean,
    required: false,
    default: true,
  },
  spaceFromFooter: {
    type: Boolean,
    required: false,
    default: true,
  },
},
  setup(__props) {

const props = __props



const { setToolbar } = useHeaderToolbar();
onBeforeMount(() => {
  // writes in store: props.toolbar
  //setToolbar(props.toolbar);
});

return { __sfc: true,props, setToolbar }
}

}